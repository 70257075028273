import React from "react"
import { FormattedMessage } from "react-intl"
import { isBrowser, loginCallback, logout } from "@services/auth"
import { navigate } from "gatsby"

const LoginCallbackPage = () => {
  console.log("Logging in")
  const getToken = async () => {
    try {
      const result = await loginCallback()
      const url = new URL(result?.state)
      navigate(url.pathname, { replace: true })
    } catch (err) {
      console.log(err)
      window.alert("登入發生錯誤, 請重新登入帳戶")
      logout()
    }
  }

  if (isBrowser) {
    getToken()
  }

  return <FormattedMessage id="SigningIn" />
}

export default LoginCallbackPage
